import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode} from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TokenHelper } from '../helpers/tokenHelper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  public post<T>(url:string, body: any): Observable<T>{
    return this.http.post<T>(this.baseApiUrl + url, body, {headers: this.getHeaders()})
    .pipe(catchError(this.handleError));
  }

  public get<T>(url:string): Observable<T>{
    return this.http.get<T>(this.baseApiUrl + url, {headers: this.getHeaders()})
    .pipe(catchError(this.handleError));
  }

  private getHeaders(): HttpHeaders {
    const token = TokenHelper.obter();

    return new HttpHeaders({
      'Content-Type': 'application/json',
      ...token && {
        'Authorization': `Bearer ${token}`,
      }
    });
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      console.error('O ocorreu um erro:', error.error);
    }
    else if(error.status === HttpStatusCode.Forbidden){
      TokenHelper.remover();
      this.router.navigate(['/Login']);
    }
    else {
      console.error(
        `O Servidor retornou um erro Status: ${error.status}, body contem: `, error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

}
