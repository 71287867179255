import { Component, OnInit } from '@angular/core';
import { dadosPartidaMeuSetor, Portao } from '../../../models/dadosMeuSetorModel';
import {StatusService} from '../../../services/status.service';
import {HomeService} from "../../../services/home.service";
import { TituloTermometroComponent } from '../../../templates/titulo-termometro/titulo-termometro.component';
import { TermometroComponent } from '../../shared/termometro/termometro.component';
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import { environment } from 'src/environments/environment';
import { SetorDDL } from 'src/app/models/entradasEmTempoReal.model';
import { animate, style, transition, trigger } from '@angular/animations';
import {formatDate} from "@angular/common";
import { PopupPublicidadeComponent } from '../../publicidade/popup-publicidade/popup-publicidade.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicidadeService } from 'src/app/services/publicidade.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  animations: [
    trigger('modalCarregando', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 0.7 })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0 }))
      ])
    ])
  ],
  styleUrls: ['./base.component.css']
})
export class BaseComponent  implements OnInit {
  atualizarDadosPorMinuto: boolean = environment.atualizarDadosPorMinuto;
  title = 'Temperatura De Acesso';
  carregando: boolean = false;

  dadosPartida: dadosPartidaMeuSetor|null = null;

  dadosConfronto: dadosPartidaMeuSetor|null = null;
  dataHoraInicio: Date = new Date();
  dataHoraInicioFormatada: string;
  dataHoraUpdate: Date = new Date();

  portaoMeusetor: Portao[] = [];

  idSetor: number = 7;

  setoresArena: SetorDDL[] = [];

  publicidadeMobile = {
    linkPublicitario: "",
    urlImagem: "",
  }
  exibirPublicidadeMobile: boolean = false;

  constructor(private homeService: HomeService, public statusService: StatusService, 
    private route: ActivatedRoute, private snackBar: MatSnackBar,
    private dialog: MatDialog, private publicidadeService: PublicidadeService
    ) {
    let dataString = new Date(this.dataHoraInicio.getTime() - (this.dataHoraInicio.getTimezoneOffset() * 60000)).toISOString();
    this.dataHoraInicioFormatada = dataString.substring(0, dataString.length - 8);
  }

  idEvento: number = 0;


  atualizarPortaoSelecionado(){
    this.buscarEvento(true);
  }

  ngOnInit(): void {
    this.publicidadeService.obterPublicidade().subscribe({
      next: (dados) => {
        if(dados == null){
          return;
        }
        if(window.innerWidth >= 768){
          setTimeout(() => {
            this.dialog.open(PopupPublicidadeComponent,{ 
              disableClose: true,
              data: dados
          });
          }, 10000);
        }else{
          this.publicidadeMobile.linkPublicitario = dados.linkPublicidade;
          this.publicidadeMobile.urlImagem = dados.urlImagemMobile;
          this.exibirPublicidadeMobile = true;
        }
      },
      error: (err) => {
        this.exibirPublicidadeMobile = false;
        console.error({err});
      }
    });

    this.route.paramMap
      .subscribe(params => {
        let id: number = parseInt(params.get('id') ?? '0');
        if(!isNaN(id)){
          this.idEvento = id;
        }
        this.buscarEvento(false);
      })
  }

  setDateFrom(event: Event){
    this.dataHoraInicio = new Date((event?.target as HTMLInputElement).value);
    this.buscarEvento(true);
  }

  atualizarDadosApos1s(){
    return;
    let dataAtual = new Date();
    if(!this.dataHoraInicio || this.dataHoraInicio < dataAtual){
      this.dataHoraInicio = dataAtual;
      this.buscarEvento(true);
    }
  }

  buscarEvento(usarTempo: boolean){
    this.carregando = true;
    var result:Observable<dadosPartidaMeuSetor>;
    if (usarTempo){
      result = this.homeService.ObterEntradasMeuSetorComTempo(this.idEvento, this.dataHoraInicio, this.idSetor);
      this.dataHoraUpdate = this.dataHoraInicio;
    }
    else{
      result = this.homeService.ObterEntradasMeuSetor(this.idEvento, this.idSetor);
    }
    result.subscribe({
      next: (dadosPartida) => {
        if (dadosPartida == null)
        {
          this.snackBar.open('Evento inexistente.',
            'Ok', {
              duration: undefined,
              verticalPosition: 'top',
            });
          return;
        }
        this.dadosConfronto = dadosPartida;
        if(this.setoresArena.length == 0){
          this.setoresArena = dadosPartida.setores;
        }
        let oneSecond: number = 60_000;
        this.carregando = false;
        setTimeout(() => this.atualizarDadosApos1s(), oneSecond);
      },
      error: (err) => {
        this.snackBar.open('Evento inexistente.',
          'Ok', {
            duration: undefined,
            verticalPosition: 'top',
          });
        console.error({err});
        this.carregando = false;
      }
    });

  }
  obterMaiorData(primeiraData:Date, segundaData:Date){
    let maiorData:Date;
    if(primeiraData > segundaData)
      maiorData = primeiraData
    else
      maiorData = segundaData;

    return maiorData;
  }

  converterDateParaStringLocal(data: Date){
    return formatDate(data, 'yyyy-MM-ddTHH:mm:ss', 'en_US');
  }
}
