import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DateTimeHelper {

  convertIsoDateStringToDateTimeLocalInput(isoDate: string){
    return this.convertDateToCurrentTimeZone(new Date(isoDate)).toISOString().slice(0, 16);
  }

  convertDateToCurrentTimeZone(date: Date){
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
  }
}
