<div class="popup-publicidade">
  <div mat-dialog-title id="tituloPublicidade" class="text-end">
    <button mat-icon-button color="primary" (click)="fecharPublicidade()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content class="popup-publicidade">
    <a [href]="linkPublicitario" target="_blank">
      <img [src]="urlImagem">
    </a>

  </mat-dialog-content>
</div>