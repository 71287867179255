import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { publicidadeViewModel } from 'src/app/models/publicidadeViewMolde';
import { PublicidadeService } from 'src/app/services/publicidade.service';

@Component({
  selector: 'app-popup-publicidade',
  templateUrl: './popup-publicidade.component.html',
  styleUrls: ['./popup-publicidade.component.css']
})
export class PopupPublicidadeComponent {
  constructor(private publicidadeService: PublicidadeService,private dialogRef: DialogRef<PopupPublicidadeComponent>, @Inject(MAT_DIALOG_DATA) public data: publicidadeViewModel) {}

  urlImagem: string = this.data.urlImagemDesktop;
  linkPublicitario: string = this.data.linkPublicidade;

  fecharPublicidade(){
    this.dialogRef.close();
  }
}