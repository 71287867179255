import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-titulo-flutuante',
  templateUrl: './card-titulo-flutuante.component.html',
  styleUrls: ['./card-titulo-flutuante.component.css']
})
export class CardTituloFlutuanteComponent {
  @Input()
  customTitle: string = '';
}
