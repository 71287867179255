import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TokenHelper {
  private static tokenNome : string = "tokenTermometro";

  public static obter() :string| null{
    return localStorage.getItem(this.tokenNome);
  }

  public static adicionar(dadosToken: string) :void{
    localStorage.setItem(this.tokenNome, dadosToken);
  }

  public static remover() :void{
    localStorage.removeItem(this.tokenNome);
  }
}
