import {Component, OnInit, ViewChild} from '@angular/core';
import {EventoService} from "../../../services/evento.service";
import {ItemPesquisaEventosViewModel} from "../../../models/dadosPesquisaEventosViewModel";
import {MatDialog} from "@angular/material/dialog";
import {CadastrarEventoComponent} from "../cadastrar-evento/cadastrar-evento.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {EditarEventoComponent} from "../editar-evento/editar-evento.component";
import { PublicidadeComponent } from '../../publicidade/publicidade/publicidade.component';

@Component({
  selector: 'app-pesquisar-evento',
  templateUrl: './pesquisar-evento.component.html',
  styleUrls: ['./pesquisar-evento.component.css']
})
export class PesquisarEventoComponent implements OnInit {
  constructor(private eventoService: EventoService, private dialog: MatDialog) {
  }

  colunasTabela: string[] = ['id', 'nome', 'dataInicio', 'opcoes']
  eventos: ItemPesquisaEventosViewModel[] = [];
  dataSource: MatTableDataSource<ItemPesquisaEventosViewModel> = new MatTableDataSource<ItemPesquisaEventosViewModel>(this.eventos);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit(): void {
    this.buscarEventos();
  }

  buscarEventos(){
    this.eventoService.obterEventosPesquisa().subscribe({
      next: (dados) => {
        this.eventos = dados.eventos;
        this.dataSource = new MatTableDataSource<ItemPesquisaEventosViewModel>(this.eventos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  abrirFormCadastroEdicao(){
    const dialog = this.dialog.open(CadastrarEventoComponent);
    dialog.afterClosed().subscribe({
      next: (val) => {
        this.buscarEventos();
      }
    })
  }

  abrirFormCadastroEdicaoPublicidade(){
    const dialog = this.dialog.open(PublicidadeComponent);
  }

  editarEvento(id: number){
    const dialog = this.dialog.open(EditarEventoComponent, {
      data: id
    });
    dialog.afterClosed().subscribe({
      next: (val) => {
        this.buscarEventos();
      }
    })
  }

  converterDataIso(data:string){
    return new Date(data).toLocaleString();
  }

  aplicarFiltro(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  protected readonly Date = Date;
}
