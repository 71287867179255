<div class="dialog-publicidade">
  <div mat-dialog-title>Cadastrar Publicidade</div>
  <form [formGroup]="publicidadeForm" (ngSubmit)="formSubmit()">
    <mat-dialog-content>
      <input type="hidden" [ngModel]="publicidade.id" formControlName="id">
      <div class="form-eventos">
        <div class="containerFormField">
          <mat-form-field appearance="fill">
            <mat-label>Link redirecionamento</mat-label>
            <input matInput type="url" maxlength="255" required [(ngModel)]="publicidade.linkPublicidade"
              formControlName="linkPublicidade">
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <div class="containerFormField">
          <div class="containerFormField">
            <mat-form-field>
              <mat-label>Imagem Desktop</mat-label>
              <ngx-mat-file-input [(ngModel)]="imagemDesktop" (change)="atualizarBase64ImagemDesktop()"
                formControlName="imagemDesktop" accept=" .jpg,.png,.png" color="primary">
              </ngx-mat-file-input>
            </mat-form-field>
            <div *ngIf="publicidade.imagemDesktop" class="containerImagemPublicidade">
              <p class="text-center text-base">Publicidade em dispositivos desktops</p>
              <img [src]="publicidade.imagemDesktop" />
            </div>
          </div>
        </div>
        <div class="containerFormField">
          <mat-form-field>
            <mat-label>Imagem Mobile</mat-label>
            <ngx-mat-file-input [(ngModel)]="imagemMobile" (change)="atualizarBase64ImagemMobile()"
              formControlName="imagemMobile" accept=".jpg,.png,.png" color="primary">
            </ngx-mat-file-input>
          </mat-form-field>
          <div *ngIf="publicidade.imagemMobile" class="containerImagemPublicidade">
            <p class="text-center text-base">Publicidade em dispositivos mobiles</p>
            <img [src]="publicidade.imagemMobile" />
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button type="button" (click)="cancelar()">Cancelar</button>
        <button mat-raised-button color="warn" type="button" (click)="limparPublicidade()">Zerar</button>
        <button mat-raised-button color="primary" type="submit">Salvar</button>
      </div>
    </mat-dialog-content>
  </form>
</div>