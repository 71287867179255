<div mat-dialog-title>Cadastrar Evento</div>
<form [formGroup]="eventoForm" (ngSubmit)="formSubmit()">
  <mat-dialog-content>
    <div class="form-eventos">

      <div class="containerFormField">
        <mat-form-field appearance="fill">
          <mat-label>Nome do Evento</mat-label>
          <input matInput type="text" maxlength="255" required [ngModel]="evento.nome" formControlName="nome">
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Adversário</mat-label>
          <mat-select required [(ngModel)]="evento.idEquipeVisitante" formControlName="idEquipeVisitante">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownEquipes" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field class="form-field-size" appearance="fill">
          <mat-label>Data/hora do evento</mat-label>
          <input matInput type="datetime-local" [ngModel]="evento.dataHoraInicio" required
            formControlName="dataHoraInicio" />
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field class="form-field-size" appearance="fill">
          <mat-label>Data/hora de abertura dos portões</mat-label>
          <input matInput type="datetime-local" [ngModel]="evento.dataHoraAberturaPortoes" required
            formControlName="dataHoraAberturaPortoes" />
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field class="form-field-size" appearance="fill">
          <mat-label>Data/hora do fim do evento</mat-label>
          <input matInput type="datetime-local" [ngModel]="evento.dataHoraFim" required formControlName="dataHoraFim" />
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Sol/Chuva</mat-label>
          <mat-select required [(ngModel)]="evento.idProbabilidadeChuva" formControlName="idProbabilidadeChuva">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownProbabilidadeChuva" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Temperatura no início do jogo</mat-label>
          <mat-select required [(ngModel)]="evento.idTemperaturaInicioJogo" formControlName="idTemperaturaInicioJogo">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownTemperaturaInicioJogo" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Campeonato e Fase</mat-label>
          <mat-select required [(ngModel)]="evento.idCampeonatoFase" formControlName="idCampeonatoFase">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownCampeonatoFase" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Posição do Grêmio (se brasileirão)</mat-label>
          <mat-select required [(ngModel)]="evento.idPosicaoTimeCampeonato" formControlName="idPosicaoTimeCampeonato">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownPosicaoTimeCampeonato" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Escalação do Grêmio</mat-label>
          <mat-select required [(ngModel)]="evento.idEscalacaoGremio" formControlName="idEscalacaoGremio">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownEscalacaoGremio" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Escalação do Adversário</mat-label>
          <mat-select required [(ngModel)]="evento.idEscalacaoAdversario" formControlName="idEscalacaoAdversario">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownEscalacaoAdversario" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Resultado do jogo anterior (saldo)</mat-label>
          <mat-select required [(ngModel)]="evento.idResultadoJogoAnterior" formControlName="idResultadoJogoAnterior">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownResultadoJogoAnterior" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Natureza da partida (decisiva)</mat-label>
          <mat-select required [(ngModel)]="evento.idNaturezaPartida" formControlName="idNaturezaPartida">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownNaturezaPartida" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Transmissão em TV Aberta</mat-label>
          <mat-select required [(ngModel)]="evento.idTransmissaoTvAberta" formControlName="idTransmissaoTvAberta">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownTransmissaoTvAberta" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Atratividade do jogo anterior na ARENA</mat-label>
          <mat-select required [(ngModel)]="evento.idAtratividadeJogoAnterior"
            formControlName="idAtratividadeJogoAnterior">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownAtratividadeJogoAnterior" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Atratividade do jogo seguinte na ARENA</mat-label>
          <mat-select required [(ngModel)]="evento.idAtratividadeJogoSeguinte"
            formControlName="idAtratividadeJogoSeguinte">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownAtratividadeJogoSeguinte" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Promoções especiais</mat-label>
          <mat-select required [(ngModel)]="evento.idPromocoesEspeciais" formControlName="idPromocoesEspeciais">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownPromocoesEspeciais" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button type="button" (click)="cancelar()">Cancelar</button>
    <button mat-raised-button color="primary" type="submit">Salvar</button>
  </mat-dialog-actions>
</form>
<style>
  .mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
  }

  mat-form-field {
    min-width: 300px !important;
  }

  .form-field-size {
    min-width: 250px;
  }

  .mat-mdc-dialog-actions {
    justify-content: end;
    padding-right: 16px;
  }
</style>