import { Injectable } from '@angular/core';
import { AutenticarUsuarioModel } from 'src/app/models/autenticarUsuarioModel';
import { RequestService } from '../request.service';
import { TokenHelper } from 'src/app/helpers/tokenHelper';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TokenModel } from 'src/app/models/TokenModel';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  constructor(private http:RequestService, private router: Router) { }  

  public verificarUsuarioAutenticado():boolean{
    let token = TokenHelper.obter();
    if(token) { return true; } 
    return false;
  };

  public logarUsuario(dadosLogin: AutenticarUsuarioModel, rota: string): Promise<string>{
    return firstValueFrom(this.http.post<TokenModel | null>("/Usuario/Login", dadosLogin))
    .then(value => {
      if(value){
        TokenHelper.adicionar(value.token);
        this.router.navigate([rota]);
        return "";
      }
      return "Usuario ou senha incorretos";
    });
  }
}
