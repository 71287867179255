<div>
  <div class="text-end mb-0">
    <span class="text-xs font-semibold italic"><ng-content select="[slot=nomeSetor]"></ng-content></span>
  </div>
  <div *ngFor="let portao of portoesSetor" class="containerPortao mb-2 flex flex-col ">
    <div>
      <span class="font-medium text-sm">PORTÃO {{portao.nome}}:</span>
    </div>
    <div class="grid grid-cols-12 items-center text-xs">
      <span class="col-span-1">CAT:</span>
      <div class="col-span-11 ml-2">
        <span *ngFor="let catraca of portao.catracas" class="nomeCatraca text-center text-xs"
          [ngClass]="statusService.obterClasseStatusMovimentoCatraca(catraca.status)">
          {{ catraca.nome }}
        </span>
      </div>
    </div>
  </div>
</div>