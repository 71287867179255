import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FileHelper } from 'src/app/helpers/fileHelper';
import { cadastrarEditarPublicidadeModel } from 'src/app/models/cadastrarEditarPublicidadeViewModel';
import { IForm } from 'src/app/models/iForm';
import { CortarImagemPublicidadeComponent } from '../cortar-imagem-publicidade/cortar-imagem-publicidade.component';
import { DialogRef } from '@angular/cdk/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicidadeService } from 'src/app/services/publicidade.service';

@Component({
  selector: 'app-publicidade',
  templateUrl: './publicidade.component.html',
  styleUrls: ['./publicidade.component.css']
})
export class PublicidadeComponent {
  constructor(
    private formBuilder: FormBuilder, 
    private fileHelper: FileHelper,
    private dialog: MatDialog,
    private dialogRef: DialogRef<PublicidadeComponent>, 
    private snackBar: MatSnackBar,
    private publicidadeService: PublicidadeService){

  }

  publicidade: IForm<cadastrarEditarPublicidadeModel> = {
    id: 0,
    linkPublicidade: '',
    imagemDesktop: '',
    imagemMobile: '',
  };
  imagemDesktop : any = null;
  imagemMobile : any = null;

  publicidadeForm: FormGroup = this.formBuilder.group(this.publicidade);

  atualizarBase64ImagemDesktop(){
    if(this.imagemDesktop != null){
      this.fileHelper.fileToBase64(this.imagemDesktop)
      .then((result) => {
        this.publicidade.imagemDesktop = result;
        const dialog = this.dialog.open(CortarImagemPublicidadeComponent,
          {
            data:{ 
              caminhoImagem: this.publicidade.imagemDesktop, 
              aspectRatio: 4 / 3,
              width: 800,
              height: 600 }
          });
  
          dialog.afterClosed().subscribe(result => {
            if(!result){
              this.imagemDesktop = null;
            }
            this.publicidade.imagemDesktop = result
          });
      });
    }
  }

  atualizarBase64ImagemMobile(){
    if(this.imagemMobile != null){
      this.fileHelper.fileToBase64(this.imagemMobile)
      .then((result) => {
        this.publicidade.imagemMobile = result;
        const dialog = this.dialog.open(CortarImagemPublicidadeComponent,
          {
            data:{ 
              caminhoImagem: this.publicidade.imagemMobile,
              aspectRatio: 17 / 8,
              width: 300,
              height: 850
            }
          });
    
          dialog.afterClosed().subscribe(result => {
            if(!result){
              this.imagemMobile = null;
            }
            this.publicidade.imagemMobile = result
          });
      });
    }
  }

  formSubmit(){
    if (this.publicidadeForm.valid) {
      this.publicidadeService.cadastrarPublicidade(this.publicidade).subscribe({
        next: (dados) => {
          this.dialogRef.close();
          this.snackBar.open(dados.msgResultado, 'Ok', {
            duration: 5000,
            verticalPosition: 'top',
          });
        },
        error: (err) => {
          this.snackBar.open('Não foi possível realizar o cadastrar a publicidade, tente novamente mais tarde. Caso o problema persista, entre em contato com o suporte.',
            'Ok', {
              duration: 5000,
              verticalPosition: 'top',
            });
        }
      });
    }
    else {
      this.snackBar.open('Confira o preenchimento do formulário antes de salvar.', 'Ok', {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
  }

  ngOnInit(): void {
    this.publicidadeService.obterPublicidade().subscribe({
      next: (dados) => {
        this.publicidade.imagemDesktop = dados.urlImagemDesktop;
        this.publicidade.imagemMobile = dados.urlImagemMobile;
        this.publicidade.linkPublicidade = dados.linkPublicidade;
        console.log(dados);
      },
      error: (err) => {
        console.error({err});
      }
    });
  }

  cancelar(){
    this.dialogRef.close();
  }

  limparPublicidade(){
    this.publicidadeService.limparPublicidade().subscribe({
      next: (dados) => {
        this.dialogRef.close();
        this.snackBar.open(dados.msgResultado, 'Ok', {
          duration: 5000,
          verticalPosition: 'top',
        });
      },
      error: (err) => {
        this.snackBar.open('Não foi possível realizar o cadastrar a publicidade, tente novamente mais tarde. Caso o problema persista, entre em contato com o suporte.',
          'Ok', {
            duration: 5000,
            verticalPosition: 'top',
          });
      }
    });
  }
}
