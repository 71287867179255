import { Component, Injectable, Input } from '@angular/core';

@Injectable()
@Component({
  selector: 'app-termometro',
  templateUrl: './termometro.component.html',
  styleUrls: ['./termometro.component.css']
})
export class TermometroComponent {
  @Input() status: number|undefined = 0;
}
