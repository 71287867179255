import { Component, Input } from '@angular/core';
import {Portao} from "../../models/entradasEmTempoReal.model";
import {StatusService} from '../../services/status.service';


@Component({
  selector: 'app-listar-catracas-component',
  templateUrl: './listar-catracas-component.component.html',
  styleUrls: ['./listar-catracas-component.component.css']
})
export class ListarCatracasComponentComponent {
  @Input() portoesSetor : Portao[] = []; 

  constructor(public statusService: StatusService) {
  
  }
}
