import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {HomeViewModel} from "../models/entradasEmTempoReal.model";
import {Observable} from "rxjs";
import { RequestService } from './request.service';
import { dadosPartidaMeuSetor } from '../models/dadosMeuSetorModel';
import {CadastrarSugestaoPortaoViewModel} from "../models/cadastrarSugestaoPortaoViewModel";
import {EventoModel} from "../models/eventoModel";
import {DefaultResultModel} from "../models/defaultResultModel";

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private request:RequestService) { }

  obterDadosEntradasEmTempoReal(idEvento: number, idSetor: number): Observable<HomeViewModel> {
    return this.request.get<HomeViewModel>(`/Home/ObterDadosEvento?idEvento=${idEvento}&idSetor=${idSetor}`);
  }

  obterDadosEntradasEmTempoRealComTempo(idEvento: number, dataHora: Date): Observable<HomeViewModel> {
    return this.request.get<HomeViewModel>(`/Home/ObterDadosEvento?idEvento=${idEvento}&dataHora=${dataHora.toISOString()}`);
  }

  obterDadosEntradasEmTempoRealComTempoPorSetor(idEvento: number, dataHora: Date, idSetor: number): Observable<HomeViewModel> {
    return this.request.get<HomeViewModel>(`/Home/ObterDadosEvento?idEvento=${idEvento}&dataHora=${dataHora.toISOString()}&idSetor=${idSetor}`);
  }

  ObterEntradasMeuSetor(idEvento: number, idSetor: number): Observable<dadosPartidaMeuSetor> {
    return this.request.get<dadosPartidaMeuSetor>(`/Home/ObterEntradasMeuSetor?idEvento=${idEvento}&idSetor=${idSetor}`);
  }
  ObterEntradasMeuSetorComTempo(idEvento: number, dataHora: Date, idSetor: number): Observable<dadosPartidaMeuSetor> {
    return this.request.get<dadosPartidaMeuSetor>(`/Home/ObterEntradasMeuSetor?idEvento=${idEvento}&dataHora=${dataHora.toISOString()}&idSetor=${idSetor}`);
  }

  obterDadosCadastroSugestaoSetor(): Observable<CadastrarSugestaoPortaoViewModel> {
    return this.request.get<CadastrarSugestaoPortaoViewModel>(`/Home/ObterDadosCadastroSugestaoSetor`);
  }

  cadastrarSugestaoPortaoSetor(data: EventoModel){
    return this.request.post<DefaultResultModel>("/Home/CadastrarSugestaoPortaoSetor", data);
  }
}
