<div class="cortarImagem">
  <div mat-dialog-title>Ajustar tamanho imagem</div>
  <mat-dialog-content>
    <div class="max-w-xl">
      <image-cropper [imageBase64]="imagem" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
        [resizeToWidth]="width" [resizeToHeight]="height" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="salvarImagemCortada()">Cortar</button>
    <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
  </div>
</div>