import { Component, Input } from '@angular/core';
import {StatusService} from '../../services/status.service';
import { DadosConfrontoViewModel } from '../../models/dadosConfrontoViewModel';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-titulo-termometro',
  templateUrl: './titulo-termometro.component.html',
  styleUrls: ['./titulo-termometro.component.css'],
})


export class TituloTermometroComponent {
  @Input() dadosConfronto: DadosConfrontoViewModel | null = null;
  @Input() funcaoBuscar!: (data: Date) => void;
  
  constructor(public statusService: StatusService){
  }
}
