<div mat-dialog-title>Cadastrar Sugestão de Portão</div>
<form [formGroup]="eventoForm" (ngSubmit)="formSubmit()">
  <mat-dialog-content>
    <div class="form-eventos">
      <div class="containerFormField">
        <mat-form-field class="form-field-size" appearance="fill">
          <mat-label>Data/hora do início da sugestão</mat-label>
          <input matInput type="datetime-local" [ngModel]="evento.dataHoraInicio" required formControlName="dataHoraInicio"/>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field class="form-field-size" appearance="fill">
          <mat-label>Data/hora do fim da sugestão</mat-label>
          <input matInput type="datetime-local" [ngModel]="evento.dataHoraFim" required formControlName="dataHoraFim"/>
        </mat-form-field>
      </div>
      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Setor</mat-label>
          <mat-select required [(ngModel)]="evento.idSetor" formControlName="idSetor" (selectionChange)="atualizarPortoes()">
            <mat-option *ngFor="let item of dadosCadastro?.dropDownSetores" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="containerFormField">
        <mat-form-field>
          <mat-label>Portão</mat-label>
          <mat-select required [(ngModel)]="evento.idPortao" formControlName="idPortao">
            <mat-option *ngFor="let item of dropDownPortoes" [value]="item.id">
              {{item.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button type="button" (click)="cancelar()">Cancelar</button>
    <button mat-raised-button color="primary" type="submit">Salvar</button>
  </mat-dialog-actions>
</form>
<style>
  .mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
  }

  mat-form-field {
    min-width: 300px !important;
  }

  .form-field-size {
    min-width: 250px;
  }
</style>
