import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardTituloFlutuanteComponent } from './templates/templateCardTituloFlutuante/card-titulo-flutuante/card-titulo-flutuante.component';
import {HttpClientModule} from "@angular/common/http";
import { TituloTermometroComponent } from './templates/titulo-termometro/titulo-termometro.component';
import { CadastrarEventoComponent } from './components/eventos/cadastrar-evento/cadastrar-evento.component';
import { EditarEventoComponent } from './components/eventos/editar-evento/editar-evento.component';
import { PesquisarEventoComponent } from './components/eventos/pesquisar-evento/pesquisar-evento.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseComponent } from './components/home/base/base.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {getPortuguesePaginatorIntl} from "./portuguese-paginator-intl";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { SelecionarEventoComponent } from './components/home/selecionar-evento/selecionar-evento.component';
import { ListarCatracasComponentComponent } from './templates/listar-catracas-component/listar-catracas-component.component';
import { VisaoGeralComponent } from './components/home/visao-geral/visao-geral.component';
import { AuthGuardService } from './services/auth/auth-guard-service.service';
import { AutenticacaoService } from './services/auth/autenticacao.service';
import { TokenHelper } from './helpers/tokenHelper';
import { TermometroComponent } from './components/shared/termometro/termometro.component';
import { CadastrarSugestaoEventoComponent } from './components/sugestao-portao/cadastrar-sugestao-evento/cadastrar-sugestao-evento.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PublicidadeComponent } from './components/publicidade/publicidade/publicidade.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CortarImagemPublicidadeComponent } from './components/publicidade/cortar-imagem-publicidade/cortar-imagem-publicidade.component';
import { PopupPublicidadeComponent } from './components/publicidade/popup-publicidade/popup-publicidade.component';
@NgModule({
  declarations: [
    AppComponent,
    CardTituloFlutuanteComponent,
    TituloTermometroComponent,
    CadastrarEventoComponent,
    EditarEventoComponent,
    PesquisarEventoComponent,
    BaseComponent,
    SelecionarEventoComponent,
    ListarCatracasComponentComponent,
    VisaoGeralComponent,
    TermometroComponent,
    CadastrarSugestaoEventoComponent,
    FooterComponent,
    PublicidadeComponent,
    CortarImagemPublicidadeComponent,
    PopupPublicidadeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxMatFileInputModule,
    ImageCropperModule
  ],
  providers: [
    AuthGuardService,
    AutenticacaoService,
    TokenHelper,
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl(), },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
