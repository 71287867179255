<div id="termometroLateral" class="static h-[30rem] w-[8rem] flex flex-col justify-center items-center">
  <div class="w-20 h-4/6 -mb-5 border-solid bg-gray-200 rounded-t-full relative z-20">
    <div class="absolute top-[18rem] left-3.5 w-8/12 h-1/6 z-20 corDisponivel" *ngIf="status==1">
    </div>
    <div class="absolute top-56 left-3.5 w-8/12 h-3/6 z-20 corAtencao" *ngIf="status==2"></div>
    <div class="absolute top-3 left-3.5 w-8/12 h-full rounded-t-full z-20 corAlerta" *ngIf="status==3"></div>
  </div>
  <div class="w-28 rounded-full h-28 bg-gray-200 relative z-10">
    <div class="absolute rounded-full top-4 left-4 w-20 h-20 z-20 corDisponivel" *ngIf="status==1">
    </div>
    <div class="absolute rounded-full top-4 left-4 w-20 h-20 z-20 corAtencao" *ngIf="status==2">
    </div>
    <div class="absolute rounded-full top-4 left-4 w-20 h-20 z-20 corAlerta" *ngIf="status==3">
    </div>
  </div>
  <div class="absolute w-2/12 right-0 h-5/6 top-[50px] bg-transparent flex flex-col gap-2">
    <div class="flex flex-col gap-[.5rem]">
      <div class="w-full bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
      <div class="w-6/12 bordaCorAlerta"></div>
    </div>
    <div class="flex flex-col gap-[.5rem]">
      <div class="w-full bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
      <div class="w-6/12 bordaCorAtencao"></div>
    </div>
    <div class="flex flex-col gap-[.5rem]">
      <div class="w-full bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-6/12 bordaCorDisponivel"></div>
      <div class="w-full bordaCorDisponivel"></div>
    </div>
  </div>
</div>