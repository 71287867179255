import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { DefaultResultModel } from '../models/defaultResultModel';
import { Observable } from 'rxjs';
import { cadastrarEditarPublicidadeModel } from '../models/cadastrarEditarPublicidadeViewModel';
import { publicidadeViewModel } from '../models/publicidadeViewMolde';

@Injectable({
  providedIn: 'root'
})
export class PublicidadeService {
  constructor(private request:RequestService) { }

  obterPublicidade():Observable<publicidadeViewModel>{
    return this.request.get<publicidadeViewModel>("/Home/ObterPublicidade");
  }

  cadastrarPublicidade(data: any){
    return this.request.post<DefaultResultModel>("/Home/CadastrarPublicidade", data);
  }
  
  limparPublicidade(){
    return this.request.get<DefaultResultModel>("/Home/LimparPublicidades");
  }
}