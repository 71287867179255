import { Component, OnInit } from '@angular/core';
import { Catraca, EntradasEmTempoReal, EntradasPorPortaoSetor, HomeViewModel, Portao, SetorDDL } from '../../../models/entradasEmTempoReal.model';
import { FooterComponent } from '../../shared/footer/footer.component';
import {HomeService} from "../../../services/home.service";
import { TituloTermometroComponent } from '../../../templates/titulo-termometro/titulo-termometro.component';
import {DadosConfrontoViewModel} from "../../../models/dadosConfrontoViewModel";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import { StatusService } from 'src/app/services/status.service';
import { environment } from 'src/environments/environment';
import {MatDialog} from "@angular/material/dialog";
import {
  CadastrarSugestaoEventoComponent
} from "../../sugestao-portao/cadastrar-sugestao-evento/cadastrar-sugestao-evento.component";
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-visao-geral',
  templateUrl: './visao-geral.component.html',
  animations: [
    trigger('modalCarregando', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 0.7 })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0 }))
      ])
    ])
  ],
  styleUrls: ['./visao-geral.component.css'],
})
export class VisaoGeralComponent implements OnInit {
  atualizarDadosPorMinuto: boolean = environment.atualizarDadosPorMinuto;
  title = 'Temperatura De Acesso';
  homeViewModel: HomeViewModel|null = null;
  entradasEmTempoReal: EntradasEmTempoReal|null = null;
  dadosConfronto: DadosConfrontoViewModel|null = null;
  dataHoraInicio: Date = new Date();
  dataHoraInicioFormatada: string;
  dataHoraUpdate: Date = new Date();
  portaoSetorSelecionado: Portao[] = [];
  setoresArena: SetorDDL[] = [];
  outrosSetores:EntradasPorPortaoSetor[] = [];
  idSetor: number = 1;
  carregando: boolean = true;

  dataHoraInicioForm:FormControl = new FormControl('dataHoraInicio');
  constructor(private homeService: HomeService, public statusService: StatusService, private route: ActivatedRoute, private snackBar: MatSnackBar, private dialog: MatDialog) {
    let dataString = new Date(this.dataHoraInicio.getTime() - (this.dataHoraInicio.getTimezoneOffset() * 60000)).toISOString();
    this.dataHoraInicioFormatada = dataString.substring(0, dataString.length - 8);
  }
  idEvento: number = 0;

  atualizarPortaoSelecionado(){
    this.buscarEvento(true);
  }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe(params => {
        let id: number = parseInt(params.get('id') ?? '0');
        if(!isNaN(id)){
          this.idEvento = id;
        }

        this.buscarEvento(false);
      })
  }

  setDateFrom(event: Event){
    this.dataHoraInicio = new Date((event?.target as HTMLInputElement).value);
    this.buscarEvento(true);
  }

  alterarSugestao(){
    this.abrirFormCadastroEdicao();
  }

  atualizarDadosApos1m(){
    if(this.atualizarDadosPorMinuto){
      return;
    }

    return;
    let dataAtual = new Date();
    if(!this.dataHoraInicio || this.dataHoraInicio < dataAtual){
      this.dataHoraInicio = dataAtual;
      this.buscarEvento(true);
    }
  }

  buscarEvento(usarTempo: boolean){
    this.carregando = true;
    var result:Observable<HomeViewModel>;
    if (usarTempo){
      result = this.homeService.obterDadosEntradasEmTempoRealComTempoPorSetor(this.idEvento, this.dataHoraInicio, this.idSetor);
      this.dataHoraUpdate = this.dataHoraInicio;
    }
    else{
      result = this.homeService.obterDadosEntradasEmTempoReal(this.idEvento, this.idSetor);
    }
    result.subscribe({
      next: (home) => {
        if (home == null)
        {
          this.snackBar.open('Evento inexistente.',
            'Ok', {
              duration: undefined,
              verticalPosition: 'top',
            });
          return;
        }
        this.homeViewModel = home;
        this.entradasEmTempoReal = home.entradasEmTempoReal;
        this.dadosConfronto = home.dadosConfronto;
        this.dadosConfronto.status = home.status;
        this.entradasEmTempoReal.status = home.status || 3;

        if(this.setoresArena.length == 0){
          this.setoresArena = home.entradasEmTempoReal.setores;
        }

        this.outrosSetores = home.entradasPorPortaoSetor.filter((data) => data.idSetor !== this.idSetor);;

        this.portaoSetorSelecionado = this.entradasEmTempoReal?.portoesMeuSetor;
        this.carregando = false;
        let oneSecond: number = 60_000;
        setTimeout(() => this.atualizarDadosApos1m(), oneSecond);
      },
      error: (err) => {
        this.snackBar.open('Evento inexistente.',
          'Ok', {
            duration: undefined,
            verticalPosition: 'top',
          });
        console.error({err});
        this.carregando = false;
      }
    });
  }

  abrirFormCadastroEdicao(){
    const dialog = this.dialog.open(CadastrarSugestaoEventoComponent);
    dialog.afterClosed().subscribe({
      next: (val) => {
        //this.buscarEvento(true);
      }
    })
  }
}
