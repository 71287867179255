import { Injectable } from '@angular/core';
import { StatusCatraca } from '../models/enums/statusCatraca';
@Injectable({
  providedIn: 'root',
})
export class StatusService {

  obterClasseStatusMovimento(valor: number|undefined): string {
    if (valor === StatusCatraca.Livre) return 'corDisponivel';
    else if (valor === StatusCatraca.Intenso) return 'corAtencao';
    else if (valor === null) return 'corDefault';
    return 'corAlerta';
  }

  obterClasseStatusMovimentoCatraca(valor: number|undefined): string {
    if (valor === StatusCatraca.Livre) return 'corDisponivelCatraca';
    else if (valor === StatusCatraca.Moderado) return 'corAtencao';
    else if (valor === StatusCatraca.Intenso) return 'corAlerta'
    return 'corDefault';
  }
  obterMensagemStatusMovimento(valor: number|undefined): string {
    if (valor === StatusCatraca.Livre) return 'Acesso tranquilo';
    else if (valor === StatusCatraca.Moderado) return 'Acesso Moderado';
    else if (valor === StatusCatraca.Intenso) return 'Acesso Intenso';
    return '';
  }
}
