import {Component, Inject, Input, OnInit} from '@angular/core';
import {EventoService} from "../../../services/evento.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogRef} from "@angular/cdk/dialog";
import {IForm} from "../../../models/iForm";
import {EventoModel} from "../../../models/eventoModel";
import {CadastrarEditarEventoViewModel} from "../../../models/cadastrarEditarEventoViewModel";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DateTimeHelper} from "../../../helpers/dateTimeHelper";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-editar-evento',
  templateUrl: './editar-evento.component.html',
  styleUrls: ['./editar-evento.component.css']
})
export class EditarEventoComponent implements OnInit {
  constructor(private eventoService: EventoService, private formBuilder: FormBuilder, private dialogRef: DialogRef<EditarEventoComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any, public dateTimeHelper: DateTimeHelper,
              private snackBar: MatSnackBar) {
  }

  idEvento: number = 0;
  dataAtualDate = new Date();
  dataAtualString = new Date(this.dataAtualDate.getTime() - (this.dataAtualDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
  evento: IForm<EventoModel> = {
    nome: '',
    dataHoraAberturaPortoes: this.dataAtualString,
    dataHoraFim: this.dataAtualString,
    dataHoraInicio: this.dataAtualString,
    idArena: 1,
    id: 0,
    idAtratividadeAdversario: 1,
    idAtratividadeJogoAnterior: 1,
    idAtratividadeJogoSeguinte: 1,
    idCampeonatoFase: 1,
    idDiaSemanaHora: 1,
    idEquipeCasa: 1,
    idEscalacaoAdversario: 1,
    idEscalacaoGremio: 1,
    idNaturezaPartida: 1,
    idPosicaoTimeCampeonato: 1,
    idProbabilidadeChuva: 1,
    idPromocoesEspeciais: 1,
    idResultadoJogoAnterior: 1,
    idSemana: 1,
    idTemperaturaInicioJogo: 1,
    idTransmissaoTvAberta: 1,
  };
  eventoForm: FormGroup = this.formBuilder.group(this.evento);
  dadosCadastro: CadastrarEditarEventoViewModel | null = null;

  ngOnInit(): void {
    this.idEvento = this.data;
    this.eventoService.obterDadosCadastrarEditarEvento(this.idEvento).subscribe({
      next: (dados) => {
        this.dadosCadastro = dados;
        this.evento = dados.evento;
        this.evento.dataHoraInicio = this.dateTimeHelper.convertIsoDateStringToDateTimeLocalInput(this.evento.dataHoraInicio);
        this.evento.dataHoraAberturaPortoes = this.dateTimeHelper.convertIsoDateStringToDateTimeLocalInput(this.evento.dataHoraAberturaPortoes);
        this.evento.dataHoraFim = this.dateTimeHelper.convertIsoDateStringToDateTimeLocalInput(this.evento.dataHoraFim);
        this.eventoForm = this.formBuilder.group(this.evento);
      },
      error: (err) => {
        console.error({err});
      }
    });

  }

  formSubmit() {
    if (this.eventoForm.valid) {
      this.eventoService.editar(this.eventoForm.value).subscribe({
        next: (dados) => {
          this.dialogRef.close();
          this.snackBar.open(dados.msgResultado, 'Ok', {
            duration: 5000,
            verticalPosition: 'top',
          });
        },
        error: (err) => {
          this.snackBar.open('Não foi possível realizar a edição do evento, tente novamente mais tarde. Caso o problema persista, entre em contato com o suporte.',
            'Ok', {
              duration: 5000,
              verticalPosition: 'top',
            });
        }
      });
    }
    else {
      this.snackBar.open('Confira o preenchimento do formulário antes de salvar.', 'Ok', {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
  }

  cancelar() {
    this.dialogRef.close();
  }
}
