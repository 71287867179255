import { Component } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import {inject} from '@angular/core';
import { AutenticacaoService } from 'src/app/services/auth/autenticacao.service';
import { AutenticarUsuarioModel } from 'src/app/models/autenticarUsuarioModel';
import { ThemePalette } from '@angular/material/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [MatInputModule, FormsModule, ReactiveFormsModule, NgIf, MatIconModule, MatButtonModule, MatCardModule, MatProgressSpinnerModule],
})


export class LoginComponent {
  qtdMinimaCaracteres :number = 6;
  msgErro :string = "";
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(this.qtdMinimaCaracteres)]);
  hide = true;
  carregando = false;
  value = 50;
  private aut = inject(AutenticacaoService);
  formularioLogin = new FormGroup({
    email: this.email,
    password: this.password
  });


  autenticarUsuario(){
    this.carregando = true;
    const emailValue = this.email.value ?? "";
    const passwordValue = this.password.value ?? "";
    let dadosUsuario = new AutenticarUsuarioModel(emailValue, passwordValue);
    this.aut.logarUsuario(dadosUsuario, "Dashboard")
    .then(res => {
      this.msgErro = res
      this.carregando = false; 
    }
    );
  }

  verificarEmail() {
    if (this.email.hasError('required')) {
      return 'Digite seu Email';
    }

    return this.email.hasError('email') ? 'Email inválido' : '';
  }
  
  verificarSenha() {
    if (this.password.hasError('required')){
      return 'Digite sua senha';
    }

    return this.password.hasError('minlength') ? `A senha deve conter no minimo ${this.qtdMinimaCaracteres} caracteres` : '';
  }

}
