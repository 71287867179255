<div id="containerBaseHome" class="flex justify-center items-center font-sans">
  <div *ngIf="homeViewModel != null" class="w-full sm:w-fit h-fit border-solid border border-gray-300
    rounded-lg bg-white flex">
    <div class="flex items-center">
      <div class="hidden md:block relative w-36 mx-2">
        <app-termometro [status]="homeViewModel.status"></app-termometro>
      </div>
    </div>
    <div class="flex flex-col">
      <app-titulo-termometro [dadosConfronto]="dadosConfronto" class="mt-4">
        <div slot="corpo" class="flex flex-col items-center md:flex-row">
          PROJEÇÃO DE DADOS: <input matInput type="datetime-local" class="border-2 pl-1 text-sm"
            [ngModel]="dataHoraInicioFormatada" (change)="setDateFrom($event)" />
        </div>
      </app-titulo-termometro>
      <div id="conteinerCartoesInfoEntrada" class="flex md:gap-y-2 mt-6 sm:mt-0 max-w-2xl">
        <div class="flex flex-col sm:flex-row w-full">
          <app-card-titulo-flutuante class="flex border-2 rounded-md">
            <span slot="titulo" class="text-md bg-gray-300">
              PREVISÃO ENTRADA MINUTO A MINUTO - ÚLTIMA ATUALIZAÇÃO {{ dataHoraUpdate | date:'HH:mm'}}
            </span>
            <div slot="corpo"
              class="flex flex-wrap max-h-fit flex-col md:flex-row justify-around divide-y-2 sm:divide-y-0 md:divide-x-2">
              <div class="md:w-6/12">
                <div class="mt-4 sm:mt-0 max-h-[18rem] h-full overflow-y-auto corpoInfoStatusVendasECatracas">
                  <div class="flex p-1 max-w-[100%] justify-end flex-row items-center align-middle">
                    <span class="text-md text-end mr-2 font-semibold min-w-[4rem]">MEU SETOR DE ACESSO:</span>
                    <select class="text-lg w-full border-2 h-10 border-solid text-center border-gray-500 font-bold"
                      [(ngModel)]="idSetor" (ngModelChange)="atualizarPortaoSelecionado()">
                      <option *ngFor="let setor of this.setoresArena" [ngValue]="setor.id">
                        {{setor.texto.toUpperCase()}}
                      </option>
                    </select>
                  </div>
                  <div
                    class="flex flex-col items-center bg-gray-100 w-full containerSugestaoAcesso justify-around py-3">
                    <div class="text-center font-semibold text-sm mt-1 w-1/3">SUGESTÃO DE ACESSO ATUAL: </div>
                    <div class="font-semibold flex flex-col items-center  w-1/6">PORTÃO: <span
                        class="text-2xl font-bold">{{
                        entradasEmTempoReal?.sugestaoPortao || "" }}</span>
                    </div>
                    <div class="font-semibold containerBotaoETextoAlterar w-1/3" (click)="alterarSugestao()"> <mat-icon
                        id="iconeAlterarPrevisao">sync</mat-icon> Alterar </div>
                  </div>
                  <div class="flex flex-col items-center mt-2">
                    <div>
                      <span class="text-sm bg-gray-300">PREVISÃO GERAL DE ACESSOS NO SETOR</span>
                    </div>
                  </div>
                  <div class="w-full px-2">
                    <app-listar-catracas-component [portoesSetor]="portaoSetorSelecionado">
                      <span slot="nomeSetor" class="text-md whitespace-nowrap"></span>
                    </app-listar-catracas-component>
                  </div>
                </div>
              </div>
              <div class="md:w-6/12">
                <div class="max-h-[18rem] h-full overflow-y-auto px-2 corpoInfoStatusVendasECatracas">
                  <span class="whitespace-nowrap font-bold text-sm flex justify-center">PREVISÃO DOS OUTROS
                    ACESSOS</span>
                  <div *ngFor="let dadosSetor of this.outrosSetores">
                    <app-listar-catracas-component [portoesSetor]="dadosSetor.portoes"
                      *ngIf="dadosSetor.portoes.length >0">
                      <span slot="nomeSetor" class="text-md whitespace-nowrap">{{ dadosSetor.nomeSetor }}</span>
                    </app-listar-catracas-component>
                  </div>
                </div>
              </div>
            </div>
          </app-card-titulo-flutuante>
        </div>
      </div>
      <app-footer class="md:-ml-32">
      </app-footer>
    </div>
  </div>
  <div>
    <div @modalCarregando *ngIf="carregando"
      class="bg-white w-screen h-screen absolute top-0 left-0 z-50 opacity-70 animate-pulse">
    </div>
    <div @modalCarregando *ngIf="carregando"
      class="w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 flex-col">
      <mat-spinner [diameter]="50"></mat-spinner>
      <span>Carregando...</span>
    </div>
  </div>
</div>