import { Injectable, inject } from '@angular/core';
import {  ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AutenticacaoService } from './autenticacao.service';

@Injectable()
export class AuthGuardService  {
  constructor(public auth: AutenticacaoService, public router: Router) {}

  public canActivate: CanActivateFn = () => {
    if (!this.auth.verificarUsuarioAutenticado()) {
      this.router.navigate(['/Login']);
      return false;
    }
    return true;
  }
}