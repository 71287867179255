import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {CadastrarEditarEventoViewModel} from "../models/cadastrarEditarEventoViewModel";
import {DadosPesquisaEventosViewModel} from "../models/dadosPesquisaEventosViewModel";
import {EventoModel} from "../models/eventoModel";
import {DefaultResultModel} from "../models/defaultResultModel";
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  constructor(private http: RequestService) { }

  obterDadosCadastrarEditarEvento(idEvento: number): Observable<CadastrarEditarEventoViewModel> {
    return this.http.get<CadastrarEditarEventoViewModel>(`/Evento/ObterDadosCadastrarEditarEvento?idEvento=${idEvento}`);
  }

  obterEventosPesquisa(): Observable<DadosPesquisaEventosViewModel> {
    return this.http.get<DadosPesquisaEventosViewModel>(`/Evento/ObterEventosPesquisa`);
  }

  cadastrar(data: EventoModel){
    return this.http.post<DefaultResultModel>("/Evento/Cadastrar", data);
  }

  editar(data: EventoModel){
    return this.http.post<DefaultResultModel>("/Evento/Editar", data);
  }
}
