<div class="flex flex-col text-center mb-4">
  <div class="font-semibold text-[12px]">POWERED BY</div>
  <div class="flex justify-center gap-3 mt-2">
    <a href="https://arenapoa.com.br/" target="_blank">
      <img class="h-8" src="/assets/imgs/logo_arena_gremio.svg" alt="Logo Arena Gremio">
    </a>
    <a href="https://sporti.com.br" target="_blank">
      <img class="h-8" src="/assets/imgs/logo_sporTi.webp" alt="Logo SporTI">
    </a>
  </div>
</div>