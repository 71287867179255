<div id="conteudoPrincipal">
  <h2 class="text-center my-1 md:my-0 text-2xl font-bold text-gray-600">TERMÔMETRO DE ACESSO</h2>
  <h5 class="text-center mb-2 text-xl">{{dadosConfronto?.nomeArena}}</h5>
  <div id="containerDadosEvento"
    class="my-3 sm:my-0 flex flex-col items-center md:flex-row gap-y-2 md:gap-0 md:gap-x-4 md:divide-x justify-center">
    <div id="containerEscudos" class="flex flex-row content-center">
      <div class="flex flex-col items-center gap-1 w-20">
        <img [src]="dadosConfronto?.caminhoImagemTimeCasa" [alt]="dadosConfronto?.nomeTimeCasa"
          class="escudoTimeConfronto" width="72px" height="72px" />
        <span>{{ dadosConfronto?.nomeTimeCasa}}</span>
      </div>
      &nbsp; <b>x</b> &nbsp;
      <div class="flex flex-col items-center gap-1 w-20">
        <img [src]="dadosConfronto?.caminhoImagemTimeVisitante" [alt]="dadosConfronto?.nomeTimeVisitante"
          class="escudoTimeConfronto" width="72px" height="72px" />
        <span>{{ dadosConfronto?.nomeTimeVisitante}}</span>
      </div>
    </div>
    <div id="containerInformacoes" class="flex flex-col pl-3">
      <span id="spanNomeDoEvento" class="text-center md:text-left uppercase">{{dadosConfronto?.nomeCampeonato}}</span>
      <span id="spanDataHoraEvento" class="text-center md:text-left">{{dadosConfronto?.dataHoraConfronto |
        date:'dd/MM/yyyy - HH:mm'}}</span>
      <span id="spanPrevisaoPublico" *ngIf="dadosConfronto?.previsaoPublicoFormatado">PREVISÃO DE PÚBLICO: <span
          class="text-center md:text-left font-bold"
          [ngClass]="statusService.obterClasseStatusMovimento(dadosConfronto?.status)">{{dadosConfronto?.previsaoPublicoFormatado}}</span></span>
      <div>
        <ng-content select="[slot=corpo]"></ng-content>
      </div>
    </div>
  </div>
</div>
<script>
</script>