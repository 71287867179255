import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-cortar-imagem-publicidade',
  templateUrl: './cortar-imagem-publicidade.component.html',
  styleUrls: ['./cortar-imagem-publicidade.component.css']
})
export class CortarImagemPublicidadeComponent {
  imagem: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio: number = 3 / 4;
  height: number = 500;
  width: number = 500;
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  
  constructor(
    public dialogRef: MatDialogRef<CortarImagemPublicidadeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private sanitizer: DomSanitizer
    ) {
    this.imagem = data.caminhoImagem;
    this.aspectRatio = data.aspectRatio;
    this.height = data.height;
    this.width = data.width;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    if(event.objectUrl){
      this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }
  }
  
  imageLoaded(image: LoadedImage) {
  }

  cropperReady() {
  }

  loadImageFailed() {
  }

  salvarImagemCortada() {
    this.dialogRef.close(this.imageCropper.crop('base64')?.base64);
  }
}

