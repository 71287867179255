import {Component, OnInit} from '@angular/core';
import {EventoService} from "../../../services/evento.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogRef} from "@angular/cdk/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IForm} from "../../../models/iForm";
import {SugestaoPortaoModel} from "../../../models/sugestaoPortaoModel";
import {CadastrarSugestaoPortaoViewModel} from "../../../models/cadastrarSugestaoPortaoViewModel";
import {HomeService} from "../../../services/home.service";
import {DropDownModel} from "../../../models/dropDownModel";

@Component({
  selector: 'app-cadastrar-sugestao-evento',
  templateUrl: './cadastrar-sugestao-evento.component.html',
  styleUrls: ['./cadastrar-sugestao-evento.component.css']
})
export class CadastrarSugestaoEventoComponent implements OnInit {
  constructor(private homeService: HomeService, private formBuilder: FormBuilder, private dialogRef: DialogRef<CadastrarSugestaoEventoComponent>,
              private snackBar: MatSnackBar) {
  }

  dataAtualDate = new Date();
  dataAtualString = new Date(this.dataAtualDate.getTime() - (this.dataAtualDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
  evento: IForm<SugestaoPortaoModel> = {
    idSetor: 0,
    idPortao: 0,
    dataHoraInicio: this.dataAtualString,
    dataHoraFim: '',
  };
  eventoForm: FormGroup = this.formBuilder.group(this.evento);
  dadosCadastro: CadastrarSugestaoPortaoViewModel | null = null;
  dropDownPortoes: DropDownModel<number>[] = [];

  ngOnInit(): void {
    this.homeService.obterDadosCadastroSugestaoSetor().subscribe({
      next: (dados) => {
        this.dadosCadastro = dados;
        this.evento.idSetor = dados.dropDownSetores[0].id;
        this.dropDownPortoes = dados.portaoPorSetor.filter(portao => portao.idSetor == this.evento.idSetor)[0].dropDownPortoes;
        this.evento.idPortao = this.dropDownPortoes[0].id;
      },
      error: (err) => {
        console.error({err});
      }
    });

  }

  formSubmit() {
    if (this.eventoForm.valid) {
      this.homeService.cadastrarSugestaoPortaoSetor(this.eventoForm.value).subscribe({
        next: (dados) => {
          this.dialogRef.close();
          this.snackBar.open(dados.msgResultado, 'Ok', {
            duration: 5000,
            verticalPosition: 'top',
          });
        },
        error: (err) => {
          this.snackBar.open('Não foi possível realizar o cadastro do evento, tente novamente mais tarde. Caso o problema persista, entre em contato com o suporte.',
            'Ok', {
              duration: 5000,
              verticalPosition: 'top',
            });
        }
      });
    }
    else {
      this.snackBar.open('Confira o preenchimento do formulário antes de salvar.', 'Ok', {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
  }

  cancelar(){
    this.dialogRef.close();
  }

  atualizarPortoes(){
    if (this.dadosCadastro == null)
      return;

    this.dropDownPortoes = this.dadosCadastro.portaoPorSetor.filter(portao => portao.idSetor == this.evento.idSetor)[0].dropDownPortoes;
    this.evento.idPortao = this.dropDownPortoes[0].id;
  }

}

