import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CadastrarEventoComponent} from "./components/eventos/cadastrar-evento/cadastrar-evento.component";
import {EditarEventoComponent} from "./components/eventos/editar-evento/editar-evento.component";
import {PesquisarEventoComponent} from "./components/eventos/pesquisar-evento/pesquisar-evento.component";
import {BaseComponent} from "./components/home/base/base.component";
import {VisaoGeralComponent} from "./components/home/visao-geral/visao-geral.component";
import { LoginComponent } from './components/autenticacao/login/login.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard-service.service';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent
  },
  {
    path: 'Login',
    component: LoginComponent
  },
  {
    path: 'Temperatura',
    component: BaseComponent
  },
  {
    path: 'Temperatura/:id',
    component: BaseComponent
  },
  {
    path: 'Eventos',
    component: PesquisarEventoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'Cadastrar',
        component: CadastrarEventoComponent
      },
      {
        path: 'Editar',
        component: EditarEventoComponent
      },      
    ]
  },
  {
    path: 'Dashboard',
    canActivate: [AuthGuard],
    component: VisaoGeralComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
