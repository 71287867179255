<div class="containerEventos">
  <div id="container-pesquisa-eventos">
    <div class="py-4">
      <p class="text-2xl mb-2">Pesquisa de Eventos</p>
      <div class="flex gap-2">
        <button mat-raised-button color="primary" (click)="abrirFormCadastroEdicao()">Cadastrar Evento</button>
        <button mat-raised-button color="primary" (click)="abrirFormCadastroEdicaoPublicidade()">Publicidade</button>
      </div>
    </div>
    <mat-form-field>
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="aplicarFiltro($event)" #input>
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
          <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
        </ng-container>

        <ng-container matColumnDef="dataInicio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data de Início</th>
          <td mat-cell *matCellDef="let row"> {{(converterDataIso(row.dataInicio))}} </td>
        </ng-container>

        <ng-container matColumnDef="opcoes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opções </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" (click)="editarEvento(row.id)"><mat-icon>edit</mat-icon></button>
            <a [routerLink]="['/Temperatura', row.id]" mat-icon-button
              color="primary"><mat-icon>remove_red_eye</mat-icon></a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasTabela"></tr>
        <tr mat-row *matRowDef="let row; columns: colunasTabela;"></tr>


        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Nenhum evento encontrado</td>
        </tr>
      </table>

      <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Selecione a página"></mat-paginator>
    </div>
  </div>
</div>

<style>
  #container-pesquisa-eventos {
    max-width: 1200px;
    margin: 0 auto;
  }

  ::ng-deep mat-paginator .mat-mdc-paginator-container {
    justify-content: space-between !important;
  }
</style>