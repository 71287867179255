<div id="containerBaseHome" class="flex justify-center items-center font-sans">
  <div *ngIf="dadosConfronto != null"
    class="h-fit w-full max-w-[375px] md:w-fit xs:h-fit border-solid border md:max-w-[50rem] bg-white border-gray-300 rounded-lg flex justify-center">
    <div class="coluna1 w-96 hidden md:flex items-center">
      <div class="w-36 md:col-span-2 md:row-span-6 relative mr-4">
        <app-termometro [status]="dadosConfronto.status"></app-termometro>
      </div>
    </div>
    <div class="coluna2 flex flex-col w-full max-w-full p-1 sm:p-4">
      <app-titulo-termometro [dadosConfronto]="dadosConfronto" class="md:col-span-8 md:row-span-2 mt-4">
        <div slot="corpo" class="flex flex-col items-center sm:flex-row">
        </div>
      </app-titulo-termometro>
      <div *ngIf="exibirPublicidadeMobile" class="flex content-center justify-center w-full">
        <a [href]="publicidadeMobile.linkPublicitario" target="_blank">
          <img [src]="publicidadeMobile.urlImagem" class="w-full h-[150px]">
        </a>
      </div>
      <div id="conteinerCartoesInfoEntrada" class="flex md:gap-y-2 md:row-span-4 mt-6 sm:mt-0">
        <div class="flex flex-col sm:flex-row w-full">
          <app-card-titulo-flutuante class="flex border-2 rounded-md">
            <span slot="titulo" class="text-md bg-gray-300">
              PREVISÃO ENTRADA MINUTO A MINUTO
            </span>
            <div slot="corpo"
              class="flex flex-wrap flex-col sm:flex-row justify-around divide-y-2 sm:divide-y-0 md:divide-x-2 ">
              <div class="w-full md:w-[36rem] h-full md:max-h-[20rem]">
                <div id="corpoInfoStatusVendasECatracas" class="py-2 h-full">
                  <div class="flex flex-col items-center md:flex-row w-full justify-end align-middle pr-2">
                    <span class="text-md text-center sm:text-end mr-2 w-full sm:max-w-[12rem]">MEU SETOR DE
                      ACESSO:</span>
                    <select
                      class="h-fit w-full sm:max-w-[19.5rem] md:text-lg border-2 border-solid text-center border-gray-500 font-bold"
                      [(ngModel)]="idSetor" (ngModelChange)="atualizarPortaoSelecionado()">
                      <option *ngFor="let setor of setoresArena" [ngValue]="setor.id">
                        {{setor.texto.toUpperCase()}}
                      </option>
                    </select>
                  </div>
                  <div class="flex flex-col items-center sm:flex-row w-full justify-end align-middle pr-2 py-2">
                    <span class="text-md text-end mr-2 w-max">PROJEÇÃO DE DADOS:</span> <input matInput
                      type="datetime-local" class="border-2 border-solid w-52 text-center border-gray-500 font-bold"
                      [value]="converterDateParaStringLocal(obterMaiorData(dataHoraInicio, dadosConfronto.dataHoraAberturaPortoes))"
                      [min]="dadosConfronto.dataHoraAberturaPortoes | date:'yyyy-MM-ddTHH:mm:ss'"
                      [max]="dadosConfronto.dataHoraFimEvento | date:'yyyy-MM-ddTHH:mm:ss'"
                      (change)="setDateFrom($event)" />
                  </div>
                  <div class="flex flex-col bg-gray-100 py-2">
                    <div class="text-center font-semibold text-sm mt-1">SUGESTÃO DE ACESSO NO MOMENTO</div>
                    <div class="flex flex-row justify-around">
                      <div class="font-semibold">RAMPA: <span
                          class="text-2xl font-bold ml-1">{{dadosConfronto.rampaAcesso}}</span>
                      </div>
                      <div class="font-semibold">PORTAO: <span
                          class="text-2xl font-bold ml-1">{{dadosConfronto.sugestaoPortao}}</span></div>
                    </div>
                  </div>
                  <div *ngIf="dadosConfronto.portoes.length == 0" class="h-24 mt-2 px-1 py-3">
                    <div class="text-center">
                      <p>No momento não temos informações sobre os portões deste setor.</p>
                    </div>
                  </div>
                  <div class="px-3 mt-2">
                    <div *ngFor="let portao of dadosConfronto.portoes" class="flex flex-col mb-3">
                      <div class="flex">
                        <span class="whitespace-nowrap w-32 md:w-28 font-semibold">PORTÃO {{ portao.nome}}:</span>
                        <div class="w-full h-6 text-center font-semibold"
                          [ngClass]="statusService.obterClasseStatusMovimentoCatraca(portao.status)">
                          {{ statusService.obterMensagemStatusMovimento(portao.status).toUpperCase()}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-card-titulo-flutuante>
        </div>
      </div>
      <app-footer class="md:-ml-32">
      </app-footer>
    </div>
  </div>
  <div>
    <div @modalCarregando *ngIf="carregando"
      class="bg-white w-screen h-screen absolute top-0 left-0 z-50 opacity-70 animate-pulse">
    </div>
    <div @modalCarregando *ngIf="carregando"
      class="w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 flex-col">
      <mat-spinner [diameter]="50"></mat-spinner>
      <span>Carregando...</span>
    </div>
  </div>
</div>