<div class="containerLogin">
  <mat-card class="containerFormLogin mat-elevation-z24 bg-white">
    <mat-card-content>
      <form [formGroup]="formularioLogin" (ngSubmit)="autenticarUsuario()">
        <div class="tituloLogin text-2xl antialiased font-medium">
          <h1>Faça seu login</h1>
        </div>
        <div class="inputEmail">
          <mat-form-field class="inputLogin">
            <mat-label>Email</mat-label>
            <input matInput placeholder="email@mail.com" [formControl]="email" required>
            <mat-error *ngIf="email.invalid">{{verificarEmail()}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="inputLogin">
            <mat-label>Senha</mat-label>
            <input matInput placeholder="Senha" [type]="hide ? 'password' : 'text'" [formControl]="password" required>
            <mat-icon matSuffix (click)="hide = !hide"
              fontIcon="{{hide ? 'visibility_off' : 'visibility' }}"></mat-icon>
            <mat-error *ngIf="password.invalid">{{verificarSenha()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="bg-red-400 py-2" *ngIf="this.msgErro">
          <p>{{ this.msgErro }}</p>
        </div>
        <div>
          <button mat-Raised-button
            class="flex flex-col items-center justify-center inputLogin botaoEntrar btnPrincipal corDefault rounded text-white"
            [disabled]="!(formularioLogin.valid && !this.carregando)">
            <div *ngIf="!this.carregando">
              Logar
            </div>
            <div *ngIf="this.carregando" class="carregandoIcon">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>
      </form>
      <div class="minhaSenhaContainer underline font-bold">
        <a href="#">Esqueci a minha senha</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>