import {Component, OnInit} from '@angular/core';
import {EventoModel} from "../../../models/eventoModel";
import {EventoService} from "../../../services/evento.service";
import {CadastrarEditarEventoViewModel} from "../../../models/cadastrarEditarEventoViewModel";
import {FormBuilder, FormGroup} from "@angular/forms";
import {IForm} from "../../../models/iForm";
import {DialogRef} from "@angular/cdk/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-cadastrar-evento',
  templateUrl: './cadastrar-evento.component.html',
  styleUrls: ['./cadastrar-evento.component.css']
})
export class CadastrarEventoComponent implements OnInit {
  constructor(private eventoService: EventoService, private formBuilder: FormBuilder, private dialogRef: DialogRef<CadastrarEventoComponent>,
              private snackBar: MatSnackBar) {
  }

  dataAtualDate = new Date();
  dataAtualString = new Date(this.dataAtualDate.getTime() - (this.dataAtualDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
  evento: IForm<EventoModel> = {
    nome: '',
    dataHoraAberturaPortoes: this.dataAtualString,
    dataHoraFim: this.dataAtualString,
    dataHoraInicio: this.dataAtualString,
    idArena: 1,
    id: 0,
    idAtratividadeAdversario: 1,
    idAtratividadeJogoAnterior: 1,
    idAtratividadeJogoSeguinte: 1,
    idCampeonatoFase: 1,
    idDiaSemanaHora: 1,
    idEquipeVisitante: undefined,
    idEscalacaoAdversario: 1,
    idEscalacaoGremio: 1,
    idNaturezaPartida: 1,
    idPosicaoTimeCampeonato: 1,
    idProbabilidadeChuva: 1,
    idPromocoesEspeciais: 1,
    idResultadoJogoAnterior: 1,
    idSemana: 1,
    idTemperaturaInicioJogo: 1,
    idTransmissaoTvAberta: 1,
  };
  eventoForm: FormGroup = this.formBuilder.group(this.evento);
  dadosCadastro: CadastrarEditarEventoViewModel | null = null;

  ngOnInit(): void {
    this.eventoService.obterDadosCadastrarEditarEvento(0).subscribe({
      next: (dados) => {
        this.dadosCadastro = dados;
      },
      error: (err) => {
        console.error({err});
      }
    });

  }

  formSubmit() {
    if (this.eventoForm.valid) {
      this.eventoService.cadastrar(this.eventoForm.value).subscribe({
        next: (dados) => {
          this.dialogRef.close();
          this.snackBar.open(dados.msgResultado, 'Ok', {
            duration: 5000,
            verticalPosition: 'top',
          });
        },
        error: (err) => {
          this.snackBar.open('Não foi possível realizar o cadastro do evento, tente novamente mais tarde. Caso o problema persista, entre em contato com o suporte.',
            'Ok', {
              duration: 5000,
              verticalPosition: 'top',
            });
        }
      });
    }
    else {
      this.snackBar.open('Confira o preenchimento do formulário antes de salvar.', 'Ok', {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
  }

  cancelar(){
    this.dialogRef.close();
  }
}
