import { Component, OnInit } from '@angular/core';
import { EntradasEmTempoReal, HomeViewModel } from './models/entradasEmTempoReal.model';
import {StatusService} from './services/status.service';
import {HomeService} from "./services/home.service";
import { TituloTermometroComponent } from './templates/titulo-termometro/titulo-termometro.component';
import { DadosConfrontoViewModel } from './models/dadosConfrontoViewModel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Temperatura De Acesso';
  constructor(private homeService: HomeService, public statusService: StatusService) {
  }

  ngOnInit(): void {
  }
}
